import {ITenant, ITenantInfo} from 'modules/tenant/models';
import {getCourseCoverAssetUrl, CourseCoverSize} from 'modules/course/utils/cover';
import {getLegacyUserImageURL} from 'modules/file/api';
import {defaultProfileImage} from 'shared/utils/assets';
import {LegacyUserImageKind} from 'modules/file/models';

const defaultDashboardCoverName = 'grass2';
export const tenantLocalStorageKey = 'tenant';
export const tenantInfoLocalStorageKey = 'tenantInfo';

export const getTenantCoverImageUrl = (tenant: ITenantInfo | ITenant) => {
    if (tenant.cover_image?.url) {
        return `${tenant.cover_image.url}=w1600-h500-n`;
    } else {
        return getCourseCoverAssetUrl(defaultDashboardCoverName, CourseCoverSize.Large);
    }
};

export const getTenantLogoUrl = (tenantInfo: ITenantInfo | ITenant): string => {
    if (tenantInfo.logo?.image_key) {
        return getLegacyUserImageURL({
            tenant_id: tenantInfo.id,
            kind: LegacyUserImageKind.Tenant,
            ...tenantInfo.logo.image_key,
        });
    } else {
        return defaultProfileImage;
    }
};

export const setTenantInfoLocalStorage = (tenantInfo: ITenantInfo) => {
    localStorage.setItem(tenantInfoLocalStorageKey, JSON.stringify(tenantInfo));
};

export const removeTenantInfoLocalStorage = () => {
    localStorage.removeItem(tenantInfoLocalStorageKey);
};

export function readTenantInfoLocalStorage(): ITenantInfo | undefined {
    const tenantInfoData = localStorage.getItem(tenantInfoLocalStorageKey);
    if (tenantInfoData) {
        try {
            return JSON.parse(tenantInfoData);
        } catch (e) {
            console.warn('[Tenant Info Local Storage] ==> invalid local storage value');
        }
    }
    return undefined;
}

export const readTenantFromLocalStorage = (): ITenant | undefined => {
    const storageValue = localStorage.getItem(tenantLocalStorageKey);
    if (storageValue) {
        try {
            return JSON.parse(storageValue);
        } catch (e) {
            console.warn('[Tenant Local Storage] ==> invalid local storage value');
        }
    }
    return undefined;
};

export const writeTenantToLocalStorage = (tenant: ITenant) => {
    localStorage.setItem(tenantLocalStorageKey, JSON.stringify(tenant));
};

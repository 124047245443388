import axios, {AxiosError, AxiosResponse} from 'axios';

import {toast} from 'modules/toast/utils';

export const formatErrorMessage = (prefix: string, error: AxiosError): string => {
    return `${prefix}: ${getErrorMessage(error)}`;
};

export const getErrorMessage = (error: AxiosError) => {
    const responseError = error.response ? getResponseError(error.response) : null;
    if (responseError) {
        return responseError;
    } else {
        if (error.message && error.response?.statusText) {
            return `${error.message}: ${error.response.statusText}`;
        } else {
            return error.message;
        }
    }
};

export const getResponseError = (response: AxiosResponse) => {
    try {
        const data = response.data;
        if (typeof data == 'string') {
            return data;
        } else if ('detail' in data && typeof data.detail == 'string') {
            return data.detail;
        } else if ('detail' in data && Array.isArray(data.detail)) {
            const detail = data.detail;
            const name = detail[0].loc[detail[0].loc.length - 1];
            const message = detail[0].msg;
            return `${name}: ${message}`;
        }
    } catch {
        return null;
    }
};

export const toastAxiosError = (error: unknown): void => {
    if (axios.isAxiosError(error)) {
        toast.error(getErrorMessage(error));
    } else {
        throw error;
    }
};

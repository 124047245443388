import React from 'react';

import {useSetRecoilState, useRecoilValueLoadable} from 'recoil';

import {ITenantInfo} from 'modules/tenant/models';
import {tenantInfoReadSelector, tenantInfoAtom} from 'modules/tenant/state/tenant-info';
import {Config} from 'config';
import {readTenantInfoLocalStorage, setTenantInfoLocalStorage} from 'modules/tenant/utils';

interface IUseTenantInfoValue {
    tenantInfo: ITenantInfo;
    setTenantInfo: (tenant: ITenantInfo) => void;
}

export const useTenantInfo = (): IUseTenantInfoValue => {
    const setTenantInfo = useSetRecoilState(tenantInfoAtom);
    const tenantInfoLoadable = useRecoilValueLoadable(tenantInfoReadSelector);
    const tenantInfoInCache = React.useMemo(() => readTenantInfoLocalStorage(), []);

    // Always return the value from the server once its loaded even if it is undefined. Only return the cached value
    // while loading. It's possible that the cached value has been deleted on the server.
    const tenantInfoResult = React.useMemo<ITenantInfo | undefined>(() => {
        if (tenantInfoLoadable.state === 'hasValue') {
            setTenantInfoLocalStorage(tenantInfoLoadable.contents);
            return tenantInfoLoadable.contents;
        } else if (tenantInfoInCache) {
            // Config.tenantId can change while testing on localhost, so we need to check it matches the cached value
            // before using it
            if (Config.tenantId && tenantInfoInCache.id.toString() !== Config.tenantId) {
                console.warn('[Tenant Info] ==> tenant info in local storage is for a different tenant');
                return undefined;
            } else {
                return tenantInfoInCache;
            }
        }
    }, [tenantInfoInCache, tenantInfoLoadable]);

    // store tenant info in localstorage once loaded
    React.useEffect(() => {
        if (tenantInfoResult) {
            setTenantInfo(tenantInfoResult);
        }
    }, [tenantInfoResult, setTenantInfo]);

    if (tenantInfoResult && tenantInfoLoadable.state !== 'hasError') {
        return {
            tenantInfo: tenantInfoResult,
            setTenantInfo,
        };
    } else if (tenantInfoLoadable.state === 'loading') {
        throw tenantInfoLoadable.toPromise();
    } else {
        throw new Error('Tenant not found');
    }
};

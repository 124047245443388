import axios from 'axios';

import {ITenant, ITenantUpdate, ITenantInfoByHostQuery, ITenantInfoByIDQuery, ITenantInfo} from '../models';
import {getAccessTokenSilently} from 'shared/auth/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {Config} from 'config';

const getPathForTenantService = (path: string): string => {
    return `${Config.tenantServiceURL}${path}`;
};

const getPathForTenantInfoService = (path: string): string => {
    return `${Config.tenantInfoServiceURL}${path}`;
};

export const readTenant = async (tenantId: number): Promise<ITenant> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getPathForTenantService(`/tenants/${tenantId}`), {
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export async function updateTenant(tenantId: number, updateData: ITenantUpdate): Promise<ITenant> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getPathForTenantService(`/tenants/${tenantId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export async function readTenantInfo(tenantInfoQuery: ITenantInfoByHostQuery | ITenantInfoByIDQuery): Promise<ITenantInfo> {
    const response = await axios.get(getPathForTenantInfoService('/'), {
        params: tenantInfoQuery,
        withCredentials: true,
    });
    return response.data;
}

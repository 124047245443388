import axios from 'axios';

import {Config} from 'config';
import {
    IFileListResult,
    IFileListQuery,
    IFileDownloadURL,
    IFileToken,
    ILegacyUserImageKey,
    IFileUploadCreate,
    IFileUpload,
    IFile,
    IUploadBlobToFileUploadURLProps,
    IFileQuery,
    IFileCountResult,
} from 'modules/file/models';
import {getAccessTokenSilently} from 'shared/auth/utils/token';
import {getAxiosHeaders} from 'shared/utils/api';

interface IGetLegacyUserImageURLProps extends ILegacyUserImageKey {
    downloadToken?: string;
}

export const getFileServiceURL = (tenantId: number, path: string) => {
    return `${Config.fileServiceURL}/tenants/${tenantId}${path}`;
};

export const getFileURL = (tenantId: number, path: string) => {
    return getFileServiceURL(tenantId, `/files${path}`);
};

export const getFileDownloadTokenURL = (tenantId: number, path: string) => {
    return getFileServiceURL(tenantId, `/token${path}`);
};

export const getLegacyUserImageURL = ({tenant_id, user_id, id, kind, downloadToken}: IGetLegacyUserImageURLProps) => {
    let path = `/legacy_user_images/${kind}/${user_id}/${id}/download`;
    if (downloadToken) {
        path = `${path}?token=${downloadToken}`;
    }
    return getFileServiceURL(tenant_id, path);
};

export const readFileList = async (tenantId: number, fileListQuery: IFileListQuery): Promise<IFileListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getFileURL(tenantId, '/'), {
        params: fileListQuery,
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const readFileCount = async (tenantId: number, fileQuery: IFileQuery): Promise<IFileCountResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getFileURL(tenantId, '/count'), {
        params: fileQuery,
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const readFileDownloadURL = async (tenantId: number, fileId: string | number): Promise<IFileDownloadURL> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getFileURL(tenantId, `/${fileId}/download_url`), {
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const createFileDownloadToken = async (tenantId: number, userId: number): Promise<IFileToken> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getFileDownloadTokenURL(tenantId, '/'),
        {
            user_id: userId,
        }, {
            headers: getAxiosHeaders(accessToken),
        },
    );
    return response.data;
};

export const createFileUpload = async ({tenant_id, ...fileUploadCreate}: IFileUploadCreate): Promise<IFileUpload> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getFileServiceURL(tenant_id, '/file_uploads/'), fileUploadCreate, {
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const createFileFromUpload = async (tenantId: number, fileUploadId: number): Promise<IFile> => {
    const accessToken = await getAccessTokenSilently();
    const createFilePayload = {
        file_upload_id: fileUploadId,
    };
    const response = await axios.post(getFileServiceURL(tenantId, '/files/'), createFilePayload, {
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const deleteFile = async (tenantId: number, fileId: string, fileToken?: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getFileServiceURL(tenantId, `/files/${fileId}`), {
        params: {
            token: fileToken,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return null;
};

export const uploadBlobToFileUploadURL = ({uploadURL, blob}: IUploadBlobToFileUploadURLProps): Promise<void> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadURL, true);
        xhr.onload = () => {
            const status = xhr.status;
            if (status === 200) {
                resolve();
            } else {
                reject('Could not upload file');
            }
        };
        xhr.onerror = () => {
            reject('Could not upload file');
        };
        xhr.setRequestHeader('Content-Type', blob.type);
        xhr.send(blob);
    });
};

import React from 'react';

import {useBackground} from 'shared/hooks/useBackground';

import './style.scss';

interface IAlertPageProps {
    title: React.ReactNode;
    description: React.ReactNode;
}

export const AlertPage = ({title, description, children}: React.PropsWithChildren<IAlertPageProps>) => {
    useBackground('darker');

    return (
        <div className="AlertPage">
            <div className="AlertPage__header">
                <h1 className="AlertPage__title">{title}</h1>
                <p className="AlertPage__description">{description}</p>
            </div>
            {children && (
                <div className="AlertPage__description">
                    {children}
                </div>
            )}
        </div>
    );
};

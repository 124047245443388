import {createContext, useContext, useEffect, useState} from 'react';

import {AuthClient, IPasswordLoginParams} from 'shared/auth/components/AuthClient';
import {ITokenData} from 'shared/auth/models/ITokenData';

interface IUseAuthProviderProps {
    tenantId: number;
    apiUrl: string;
}

interface IUseAuthProviderValue {
    tenantId: number | null;
    tokenData: ITokenData | null;
    isLoading: boolean;
    isAuthenticated: boolean;
    getAccessTokenSilently: () => Promise<string>;
    signOut: () => Promise<void>;
    authenticateWithCredentials: (loginParams: IPasswordLoginParams) => Promise<string>;
    setAccessToken: (accessToken: string) => void;
}

interface IUseGuaranteedAuthValue extends IUseAuthProviderValue {
    tenantId: number;
    tokenData: ITokenData;
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialAuthContext: IUseAuthProviderValue = {
    isLoading: true,
    isAuthenticated: false,
    tenantId: null,
    tokenData: null,
    getAccessTokenSilently: stub,
    signOut: stub,
    authenticateWithCredentials: stub,
    setAccessToken: stub,
};

interface IUseAuthProviderState {
    tokenData: ITokenData | null;
    isLoading: boolean;
    isAuthenticated: boolean;
}

export const authContext = createContext<IUseAuthProviderValue>(initialAuthContext);

export const useAuth = () => {
    return useContext(authContext);
};

export const useGuaranteedAuth = (): IUseGuaranteedAuthValue => {
    const {tokenData, tenantId, ...rest} = useContext(authContext);
    if (!tokenData) {
        throw new Error('not authenticated');
    }
    if (!tenantId) {
        throw new Error('not authenticated');
    }
    return {
        tokenData,
        tenantId,
        ...rest,
    };
};

const getUserFromClient = (client: AuthClient): ITokenData | null => {
    if (client.userId && client.scopes) {
        return {
            id: client.userId,
            scopes: client.scopes,
        };
    }
    return null;
};

// Provider hook that creates auth object and handles state
export function useAuthProvider({apiUrl, tenantId}: IUseAuthProviderProps): IUseAuthProviderValue {
    const [client] = useState<AuthClient>(
        new AuthClient({
            tenantId,
            apiUrl,
        }),
    );

    const [authState, setAuthState] = useState<IUseAuthProviderState>({
        tokenData: getUserFromClient(client),
        isAuthenticated: client.isAuthenticated || false,
        isLoading: !client.isAuthenticated,
    });

    // initial check to see if the user is authenticated
    useEffect(() => {
        if (authState.isAuthenticated) {
            return;
        }
        (async (): Promise<void> => {
            try {
                await client.getAccessTokenSilently();
                setAuthState({
                    tokenData: getUserFromClient(client),
                    isLoading: false,
                    isAuthenticated: true,
                });
            } catch (error) {
                setAuthState({
                    tokenData: null,
                    isLoading: false,
                    isAuthenticated: false,
                });
            }
        })();
        // ignore missing dependencies to ensure it only runs once:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAccessTokenSilently = async (): Promise<string> => {
        try {
            const accessToken = await client.getAccessTokenSilently();
            setAuthState({
                tokenData: getUserFromClient(client),
                isLoading: false,
                isAuthenticated: true,
            });
            return accessToken;
        } catch (e) {
            setAuthState({
                tokenData: null,
                isLoading: false,
                isAuthenticated: false,
            });
            throw e;
        }
    };

    const signOut = async () => {
        const isAuthenticated = client.isAuthenticated;
        try {
            await client.signOut();
            setAuthState({
                tokenData: null,
                isLoading: false,
                isAuthenticated: false,
            });
            return;
        } catch (error) {
            setAuthState({
                tokenData: null,
                isLoading: false,
                isAuthenticated: false,
            });
            throw error;
        } finally {
            if (isAuthenticated) {
                window.location.reload();
            }
        }
    };

    const authenticateWithCredentials = async (loginParams: IPasswordLoginParams): Promise<string> => {
        try {
            const accessToken = await client.authenticateWithCredentials(loginParams);
            setAuthState({
                tokenData: getUserFromClient(client),
                isLoading: false,
                isAuthenticated: true,
            });
            return accessToken;
        } catch (error) {
            setAuthState({
                tokenData: null,
                isLoading: false,
                isAuthenticated: false,
            });
            throw error;
        }
    };

    const setAccessToken = (accessToken: string) => {
        client.setAccessToken(accessToken);
        setAuthState({
            tokenData: getUserFromClient(client),
            isLoading: false,
            isAuthenticated: true,
        });
    };

    // Return the user object and auth methods
    return {
        tenantId,
        tokenData: authState.tokenData,
        isLoading: authState.isLoading,
        isAuthenticated: authState.isAuthenticated,
        getAccessTokenSilently,
        signOut,
        authenticateWithCredentials,
        setAccessToken,
    };
}

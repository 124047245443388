import React from 'react';

import {authContext, useAuthProvider} from 'shared/auth/hooks/useAuth';
import {useTenantInfo} from 'modules/tenant/hooks/useTenantInfo';

interface IAuthProviderProps {
    apiUrl: string;
    children?: React.ReactNode;
}

export function AuthProvider({children, apiUrl}: IAuthProviderProps) {
    const {tenantInfo} = useTenantInfo();
    const auth = useAuthProvider({
        apiUrl,
        tenantId: tenantInfo.id,
    });

    if (auth.isLoading) {
        return (
            <></>
        );
    } else {
        return (
            <authContext.Provider value={auth}>{children}</authContext.Provider>
        );
    }
}

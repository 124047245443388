import React from 'react';

import {ToastContainer as ToastifyToastContainer} from 'react-toastify';

import './style.scss';

export const ToastContainer = () => (
    <ToastifyToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
    />
);

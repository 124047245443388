import React from 'react';

import {Spinner as ReactBootstrapSpinner} from 'react-bootstrap';

export type SpinnerSizeProp = undefined | 'sm';

interface ISpinnerProps {
    variant?: string;
    size?: SpinnerSizeProp;
    className?: string;
}

export const Spinner = ({variant, size, className}: ISpinnerProps) => (
    <ReactBootstrapSpinner animation="border" variant={variant} size={size} className={className}/>
);

Spinner.defaultProps = {
    variant: 'primary',
};

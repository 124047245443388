import React from 'react';

import {useRecoilValue} from 'recoil';
import {CSSTransition} from 'react-transition-group';

import {loadingPageState} from 'shared/state/loading-page';

import {Spinner} from 'shared/components/Spinner/Spinner';

import 'shared/components/loading/LoadingPage/style.scss';

export const LoadingPage = () => {
    const showLoadingPage = useRecoilValue(loadingPageState);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const loadingRef = React.useRef<HTMLDivElement | null>(null);

    // Prevent spinner flashes by adding a delay before hiding it. For example, a child component might hide it when
    // unmounted, only to have the parent show it because it is still loading.
    React.useEffect(() => {
        if (!showLoadingPage && showSpinner) {
            const timeout = setTimeout(() => {
                setShowSpinner(false);
            }, 10);
            return () => {
                clearTimeout(timeout);
            };
        } else if (showLoadingPage && !showSpinner) {
            setShowSpinner(true);
        }
    }, [showLoadingPage, showSpinner]);

    return (
        <CSSTransition
            nodeRef={loadingRef}
            in={showLoadingPage}
            timeout={300}
            classNames="LoadingPage"
            unmountOnExit={true}
        >
            <div className="LoadingPage vh-100 vw-100" ref={loadingRef}>
                <div className="LoadingPage__content">
                    {showSpinner && (
                        <Spinner className="mb-4"/>
                    )}
                </div>
            </div>
        </CSSTransition>
    );
};

import {atom, selectorFamily, selector} from 'recoil';

import {guardRecoilDefaultValue} from 'shared/utils/recoil';

export interface INavbarState {
    show?: boolean;
    transparent?: boolean;
    priority?: number;
    bg?: string;

    [key: string]: string | boolean | undefined | number;
}

export interface INavbarValue extends INavbarState {
    key: string;
}

export const navbarAtom = atom<INavbarValue[]>({
    key: 'navbarAtom',
    default: [],
});

const defaultNavbarState: INavbarValue = {
    key: 'navbarState.default',
    show: false,
    transparent: false,
};

export const navbarStateSelector = selector<INavbarState>({
    key: 'navbarStateSelector',
    get: ({get}) => {
        const navbarAtomValue = get(navbarAtom);
        const sortedValues = [...navbarAtomValue];
        sortedValues.sort((navbarValueA, navbarValueB) => {
            return (navbarValueB?.priority ?? 0) - (navbarValueA?.priority ?? 0);
        });
        const navbarState = sortedValues.length > 0 ? sortedValues[0] : defaultNavbarState;
        return {
            show: navbarState?.show ?? true,
            transparent: navbarState?.transparent ?? false,
            bg: navbarState?.bg,
        };
    },
});

export const navbarSelector = selectorFamily<INavbarState | undefined, string>({
    key: 'navbarSelector',
    get: (key) => ({get}) => {
        const navbarAtomValue = get(navbarAtom);
        const stateInAtom = navbarAtomValue.find(navbarState => navbarState.key === key);
        if (stateInAtom) {
            return stateInAtom;
        } else {
            return undefined;
        }
    },
    set: (key) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const navbarValue = {
            key,
            ...newValue,
        };
        const newNavbarAtomValue = [...get(navbarAtom)];
        const index = newNavbarAtomValue.findIndex(navbarValue => navbarValue.key === key);
        if (index !== -1) {
            const valueInAtom = newNavbarAtomValue[index];
            if (
                valueInAtom.show !== newValue?.show ||
                valueInAtom.transparent !== newValue?.transparent ||
                valueInAtom.bg !== newValue?.bg
            ) {
                newNavbarAtomValue.splice(index, 1, navbarValue);
                set(navbarAtom, newNavbarAtomValue);
            }
        } else {
            newNavbarAtomValue.push(navbarValue);
            set(navbarAtom, newNavbarAtomValue);
        }
    },
});

export const navbarRemoveSelector = selectorFamily<undefined, string>({
    key: 'navbarSelector',
    get: () => () => {
        throw new Error('This is a write-only selector');
    },
    set: (key) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const navbarAtomValue = [...get(navbarAtom)];
        const index = navbarAtomValue.findIndex(navbarValue => navbarValue.key === key);
        if (index !== -1) {
            navbarAtomValue.splice(index, 1);
            set(navbarAtom, navbarAtomValue);
        }
    },
});

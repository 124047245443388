import {useLoadingPage} from 'shared/hooks/useLoadingPage';
import {useNavbar} from 'shared/hooks/useNavbar';

export const LoadingPageFallback = () => {
    useLoadingPage(true);
    useNavbar('LoadingPageFallback', {
        show: false,
        priority: 1,
    });

    return null;
};

import React from 'react';

import {useTenantInfo} from 'modules/tenant/hooks/useTenantInfo';

import {getTenantLogoUrl} from 'modules/tenant/utils';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfilePictureSharedProps} from './TenantProfilePicture';

export const TenantProfilePictureImage = ({imgClassName, ...rest}: ProfilePictureSharedProps) => {
    const {tenantInfo} = useTenantInfo();

    return (
        <ProfilePicture
            url={getTenantLogoUrl(tenantInfo)}
            alt={tenantInfo.name}
            imgClassName={imgClassName}
            {...rest}
        />
    );
};

import React from 'react';

import {useRecoilValueLoadable, useRecoilState} from 'recoil';

import {ITenant} from 'modules/tenant/models';
import {tenantAtom, tenantReadSelector} from 'modules/tenant/state/tenant';
import {readTenantFromLocalStorage, writeTenantToLocalStorage} from 'modules/tenant/utils';

export const useTenantLoadable = () => {
    const [tenant, setTenant] = useRecoilState(tenantAtom);
    const tenantLoadable = useRecoilValueLoadable(tenantReadSelector);

    const tenantInLocalStorage = React.useMemo<ITenant | undefined>(() => {
        return readTenantFromLocalStorage();
    }, []);

    // store tenant once loaded
    React.useEffect(() => {
        if (tenantLoadable.state === 'hasValue') {
            setTenant(tenantLoadable.contents);
            writeTenantToLocalStorage(tenantLoadable.contents);
        }
    }, [tenantLoadable, setTenant]);

    const tenantResult = React.useMemo<ITenant | undefined>(() => {
        if (tenant) {
            return tenant;
        } else if (tenantLoadable.state === 'hasValue') {
            return tenantLoadable.contents;
        } else if (tenantInLocalStorage) {
            return tenantInLocalStorage;
        } else {
            return undefined;
        }
    }, [tenant, tenantLoadable, tenantInLocalStorage]);

    if (tenantResult) {
        return {
            tenant: tenantResult,
            loading: false,
            error: undefined,
        };
    } else if (tenantLoadable.state === 'loading') {
        return {
            tenant: undefined,
            loading: true,
            error: undefined,
        };
    } else {
        return {
            tenant: undefined,
            loading: false,
            error: 'Tenant not found',
        };
    }
};

export const apiUrl = process.env.REACT_APP_V3_SERVICE || '';

interface IQueryStringListParam {
    name: string;
    values: (string | number)[],
}

export const getApiUrlForPath = (path: string) => {
    return `${apiUrl}${path}`;
};

export const getAuthorizationHeader = (accessToken: string) => {
    return `Bearer ${accessToken}`;
};

export const getAxiosOptions = (accessToken: string) => {
    return {
        headers: getAxiosHeaders(accessToken),
    };
};

export const getAxiosHeaders = (accessToken: string) => {
    return {
        'Authorization': getAuthorizationHeader(accessToken),
    };
};

export const serializeListQueryString = (name: string, values: (string | number)[]): string => {
    return values.map(value => `${name}=${value}`).join('&');
};

export const serializeListParams = (url: string, name: string, values: (string | number)[]): string => {
    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    const queryString = serializeListQueryString(name, values);
    return queryString?.length ? `${url}?${queryString}` : url;
};

export const serializeListParamsMulti = (url: string, params: IQueryStringListParam[]): string => {
    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    const queryString = params.map(param => serializeListQueryString(param.name, param.values))
        .filter(qs => !!qs.length)
        .join('&');
    return queryString?.length ? `${url}?${queryString}` : url;
};

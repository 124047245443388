import {toast as reactToast, TypeOptions} from 'react-toastify';

const defaultConfig = {
    autoClose: 5000,
};

export const toast = {
    info: (message: string) => {
        reactToast.info(message, defaultConfig);
    },
    success: (message: string) => {
        reactToast.success(message, defaultConfig);
    },
    error: (message: string) => {
        reactToast.error(message, defaultConfig);
    },
    warning: (message: string) => {
        reactToast.warning(message, defaultConfig);
    },
    default: (message: string) => {
        reactToast(message, defaultConfig);
    },
    dark: (message: string) => {
        reactToast.dark(message, defaultConfig);
    },
    open: (message: string, type: TypeOptions) => {
        reactToast(message, {
            ...defaultConfig,
            type,
        });
    },
};

import React from 'react';

import {useRecoilState, useSetRecoilState} from 'recoil';

import {INavbarState, navbarSelector, navbarRemoveSelector} from 'shared/state/navbar';

export const useNavbar = (key: string, navbarValue?: INavbarState) => {
    const [navbarState, setDisplayNavbar] = useRecoilState(navbarSelector(key));
    const removeNavbarValue = useSetRecoilState(navbarRemoveSelector(key));

    React.useEffect(() => {
        if (!navbarState || (
            navbarValue?.show !== navbarState.show ||
            navbarValue?.transparent !== navbarState.transparent ||
            navbarValue?.bg !== navbarState.bg
        )) {
            setDisplayNavbar(navbarValue);
        }
    }, [setDisplayNavbar, navbarValue, removeNavbarValue, navbarState]);

    // hook to remove the navbar value when un-mounted
    React.useEffect(() => {
        return () => {
            removeNavbarValue(undefined);
        };
    }, [removeNavbarValue]);
};

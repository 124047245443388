import React from 'react';

import {useTenantInfo} from 'modules/tenant/hooks/useTenantInfo';

interface ITenantInfoProviderInnerProps {
    children?: React.ReactNode;
}

export function TenantInfoProviderInner({children}: ITenantInfoProviderInnerProps) {
    const {tenantInfo} = useTenantInfo();

    React.useEffect(() => {
        if (tenantInfo.favicon?.url) {
            let link: HTMLLinkElement | null = document.querySelector('link[rel~=\'icon\']');
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = tenantInfo.favicon.url;
        }
    }, [tenantInfo.favicon?.url]);

    return (
        <>{children}</>
    );
}

import React from 'react';

import {Link} from 'react-router-dom';

import {useTitle} from 'shared/hooks/useTitle';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useTenantLoadable} from 'modules/tenant/hooks/useTenantLoadable';
import {getAssetURL} from 'shared/utils/assets';

import {AlertPage} from 'shared/components/AlertPage/AlertPage';
import {TenantProfilePicture} from 'shared/components/TenantProfilePicture/TenantProfilePicture';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

import './style.scss';

interface INotFoundPageProps {
    children?: React.ReactNode;
}

export const NotFoundPage = ({children}: INotFoundPageProps) => {
    useNavbar('NotFoundPage', {
        show: false,
    });
    useTitle('Not Found');
    const {tenant} = useTenantLoadable();

    return (
        <AlertPage
            title="Oops! Page Not Found"
            description="Click the icon to return to the home page."
        >
            {children ? (
                <>{children}</>
            ) : (
                <>
                    {tenant ? (
                        <Link to="/">
                            <TenantProfilePicture
                                size="xs"
                                round={tenant.logo?.is_round || undefined}
                                border={tenant.logo?.is_shadow}
                            />
                        </Link>
                    ) : (
                        <a href="https://www.wyzed.com/">
                            <ProfilePicture url={getAssetURL('/favicon.png')} alt="Wyzed logo" size="xs"/>
                        </a>
                    )}
                </>
            )}
        </AlertPage>
    );
};

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';
import {differenceInSeconds} from 'date-fns';

import {getErrorMessage} from 'shared/utils/error';
import {Scope} from 'shared/auth/models/Scope';
import {Config} from 'config';

export interface IPasswordLoginParams {
    username: string;
    password: string;
}

interface IJwtData {
    sub: string;
    exp: number;
    scopes: string[];
}

export interface IAuthClientOptions {
    tenantId: number;
    apiUrl: string;
}

export interface IDecodeAccessTokenResult {
    userId: number;
    expiresAt: Date;
    scopes: Scope[];
}

interface ICreateToken {
    grantType: string;
    username: string;
    password: string;
}

export class AuthClient {
    public apiUrl: string;
    public storageKey: string;
    public cookieName: string;
    public isAuthenticated: boolean;
    public headerName: string;
    public tokenExpiresAt: Date | null;
    public tenantId: number | null;
    public userId: number | null;
    public scopes: Scope[];
    public refreshTimeout: ReturnType<typeof setTimeout> | null;
    private accessToken: string | null;
    private accessTokenPromise: Promise<string> | null;
    private isInitialized: boolean = false;

    private readonly isLocalStorageEnabled: boolean;

    constructor(private options: IAuthClientOptions) {
        this.apiUrl = options.apiUrl;
        this.tenantId = options.tenantId;
        this.storageKey = process.env.REACT_APP_AUTH_STORAGE_KEY || '';
        this.cookieName = process.env.REACT_APP_AUTH_COOKIE_NAME || '';
        this.isAuthenticated = false;
        this.headerName = 'authorization';
        this.userId = null;
        this.tokenExpiresAt = null;
        this.refreshTimeout = null;
        this.scopes = [];
        this.accessToken = null;
        this.accessTokenPromise = null;
        this.isInitialized = false;

        this.isLocalStorageEnabled = !Config.baseURL && typeof Storage !== 'undefined';

        if (this.isLocalStorageEnabled && this.readIsAuthenticatedCookie()) {
            const accessToken = localStorage.getItem(this.storageKey);
            if (accessToken) {
                try {
                    this.setAccessToken(accessToken);
                } catch {
                }
            }
        }
    }

    setAccessToken(accessToken: string) {
        const {userId, expiresAt, scopes} = this.decodeAccessToken(accessToken);
        this.userId = userId;
        this.scopes = scopes;
        this.tokenExpiresAt = expiresAt;
        this.accessToken = accessToken;
        this.isAuthenticated = true;
        if (this.isLocalStorageEnabled) {
            localStorage.setItem(this.storageKey, accessToken);
        }
    }

    clearToken(): void {
        // Remove access token from local storage
        if (this.isLocalStorageEnabled) {
            localStorage.removeItem(this.storageKey);
        }

        // Remove local variables
        this.userId = null;
        this.tokenExpiresAt = null;
        this.accessToken = null;
        this.isAuthenticated = false;
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    async authenticate({grantType, username, password}: ICreateToken): Promise<string> {
        const url = `${this.apiUrl}/token/`;

        const formData = new FormData();
        formData.append('client_id', this.tenantId?.toString() || '');
        formData.append('grant_type', grantType);
        formData.append('username', username);
        formData.append('password', password);

        const response = await axios.post(url, formData, {
            withCredentials: true,
        });
        const accessToken = response.data.access_token;
        this.setAccessToken(accessToken);
        return accessToken;
    }

    async authenticateWithCredentials({username, password}: IPasswordLoginParams): Promise<string> {
        return await this.authenticate({
            grantType: 'password',
            username,
            password,
        });
    }

    async fetchAccessToken(): Promise<string> {
        if (Config.baseURL) {
            return this._fetchAccessTokenLegacy();
        } else {
            return this._fetchAccessToken();
        }
    }

    async _fetchAccessToken(): Promise<string> {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', this.tenantId?.toString() || '');

        try {
            const response = await axios.post(
                `${this.apiUrl}/token/`,
                formData,
                {
                    withCredentials: true,
                },
            );
            const accessToken = response.data.access_token;
            this.setAccessToken(accessToken);
            return accessToken;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(getErrorMessage(error));
            } else if (error instanceof Error) {
                throw new Error(error.message);
            }
            throw new Error('Error fetching token');
        } finally {
            // clear the promise
            this.accessTokenPromise = null;
        }
    }

    async _fetchAccessTokenLegacy(): Promise<string> {
        const formData = new FormData();
        formData.append('grant_type', 'legacy_cookie');
        formData.append('client_id', this.tenantId?.toString() || '');

        try {
            const response = await axios.post(
                `${this.apiUrl}/token/`,
                formData,
                {
                    withCredentials: true,
                },
            );
            const accessToken = response.data.access_token;
            this.setAccessToken(accessToken);
            return accessToken;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(getErrorMessage(error));
            } else if (error instanceof Error) {
                throw new Error(error.message);
            }
            throw new Error('Error fetching token');
        } finally {
            // clear the promise
            this.accessTokenPromise = null;
            this.isInitialized = true;
        }
    }

    async getAccessTokenSilently(): Promise<string> {
        if (!this.readIsAuthenticatedCookie() && (!Config.baseURL && this.isInitialized)) {
            this.clearToken();
            throw new Error('You need to sign in again');
        }
        if (this.accessToken && this.tokenExpiresAt && differenceInSeconds(this.tokenExpiresAt, new Date()) > 30) {
            // TODO: Check whether requested scopes differ from current scopes. If they differ then don't use the
            //  cached token
            return this.accessToken;
        }

        // return existing promise if in progress
        if (this.accessTokenPromise) {
            return await this.accessTokenPromise;
        }

        this.accessTokenPromise = this.fetchAccessToken();
        return await this.accessTokenPromise;
    }

    async signOut(): Promise<void> {
        const wasAuthenticated = this.isAuthenticated;
        try {
            await axios.get(`${this.apiUrl}/token/logout`, {
                withCredentials: true,
            });
            this.clearToken();
            if (wasAuthenticated) {
                window.location.pathname = '/';
            }
            return;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(getErrorMessage(error));
            } else if (error instanceof Error) {
                throw new Error(error.message);
            }
            throw error;
        }
    }

    private readIsAuthenticatedCookie(): boolean {
        if (Config.baseURL) {
            const cookie = Cookie.get(Config.legacyIsAuthenticatedCookie);
            return !!cookie;
        } else {
            const cookie = Cookie.get(this.cookieName);
            return !!cookie;
        }
    }

    private decodeAccessToken(accessToken: string): IDecodeAccessTokenResult {
        const tokenData: IJwtData = jwtDecode(accessToken);
        return {
            userId: parseInt(tokenData.sub),
            expiresAt: new Date(tokenData.exp * 1000),
            scopes: tokenData.scopes.map(scope => scope as Scope),
        };
    }
}

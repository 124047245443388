import {atom, selector} from 'recoil';

import {ITenant} from '../models';
import {readTenant} from '../api';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

export const tenantAtom = atom<ITenant | undefined>({
    key: 'tenantAtom',
    default: undefined,
});

export const tenantReadSelector = selector<ITenant>({
    key: 'tenantReadSelector',
    get: async ({get}): Promise<ITenant> => {
        const atomValue = get(tenantAtom);
        if (atomValue) {
            return atomValue;
        }
        const tenantId = get(tenantIdSelector);
        return await readTenant(tenantId);
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        set(tenantAtom, newValue);
    },
});

import {ICourseCover} from 'modules/course/models';
import {getAssetURL} from 'shared/utils/assets';

export enum CourseCoverSize {
    Small = '180',
    Medium = '656',
    Large = '2000',
}

export const courseCoverImageWidth = 2000;
export const courseCoverImageHeight = 600;

interface IGetCourseCoverUrlProps {
    courseCover: ICourseCover | undefined;
    isEdit?: boolean;
    size: CourseCoverSize;
}

export const getCourseCoverAssetUrl = (filename: string, size: CourseCoverSize) => {
    return `${process.env.REACT_APP_STATIC_URL as string}/covers/${filename}@${size}.jpg`;
};

export const getCourseCoverUrl = ({courseCover, isEdit, size}: IGetCourseCoverUrlProps) => {
    // TODO: fix reading legacy files
    // if (course.cover?.file_id && course.cover?.filename) {
    //     return getFileDownloadUrl(course.tenant_id, course.cover.file_id, course.cover.filename);
    // }
    if (courseCover?.asset_id) {
        return getCourseCoverAssetUrl(courseCover?.asset_id, size);
    } else {
        return getAssetURL(`/assets/images/course/cover-default-${isEdit ? 'edit' : 'public'}.png`);
    }
};

const readConfigEnvMaybe = (key: string): string | undefined => {
    if (key in process.env && process.env[key]) {
        return process.env[key] as string;
    } else {
        return undefined;
    }
};

const readConfigEnv = (key: string): string => {
    const envValue = readConfigEnvMaybe(key);
    if (envValue) {
        return envValue;
    } else {
        throw new Error(`Missing environment variable ${key}`);
    }
};

export const Config = {
    authStorageKey: readConfigEnv('REACT_APP_AUTH_STORAGE_KEY'),
    authCookieName: readConfigEnv('REACT_APP_AUTH_COOKIE_NAME'),
    helpURL: readConfigEnv('REACT_APP_HELP_URL'),
    cdnURL: readConfigEnv('REACT_APP_STATIC_URL'),
    fileServiceURL: readConfigEnv('REACT_APP_FILE_SERVICE'),
    tenantServiceURL: readConfigEnv('REACT_APP_TENANT_SERVICE'),
    tenantInfoServiceURL: readConfigEnv('REACT_APP_TENANT_INFO_SERVICE'),
    courseServiceURL: readConfigEnv('REACT_APP_V3_SERVICE'),
    userServiceURL: readConfigEnv('REACT_APP_USER_SERVICE'),
    complianceServiceURL: readConfigEnv('REACT_APP_COMPLIANCE_SERVICE'),
    notificationServiceURL: readConfigEnv('REACT_APP_NOTIFICATION_SERVICE'),
    eventServiceURL: readConfigEnv('REACT_APP_EVENT_SERVICE'),
    reportServiceURL: readConfigEnv('REACT_APP_REPORT_SERVICE'),
    tenantId: readConfigEnvMaybe('REACT_APP_TENANT_ID'),
    legacySearchURL: '/_ah/api/wyzed/v2/search',
    profileImageSize: 600,
    baseURL: readConfigEnvMaybe('REACT_APP_BASE_URL'),
    legacyIsAuthenticatedCookie: readConfigEnv('REACT_APP_LEGACY_IS_AUTHENTICATED_COOKIE'),
    legacyLearnerModeCookie: readConfigEnv('REACT_APP_LEGACY_LEARNER_MODE_COOKIE'),
    publicSearchURL: readConfigEnv('REACT_APP_PUBLIC_SEARCH_URL'),
    env: readConfigEnv('REACT_APP_ENV'),
};

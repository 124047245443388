import React from 'react';

import {LoadingPageFallback} from 'shared/components/loading/LoadingPage/LoadingPageFallback';

export interface ILoadingPageSuspenseProps {
    children: React.ReactNode;
}

export const LoadingPageSuspense = ({children}: ILoadingPageSuspenseProps) => {

    return (
        <React.Suspense
            fallback={(
                <LoadingPageFallback/>
            )}
        >
            {children}
        </React.Suspense>
    );
};

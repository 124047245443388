import React from 'react';

import './styles.scss';

const backgroundClassNameDark = 'Body--dark';
const backgroundClassNameDarker = 'Body--darker';

type UseBackgroundVariant = 'dark' | 'darker';

const removeBodyClassNames = () => {
    const body = document.getElementsByTagName('BODY')[0];
    if (body.classList.contains(backgroundClassNameDark)) {
        body.classList.remove(backgroundClassNameDark);
    }
    if (body.classList.contains(backgroundClassNameDarker)) {
        body.classList.remove(backgroundClassNameDarker);
    }
};

const getClassNameForVariant = (variant?: UseBackgroundVariant): string | undefined => {
    if (!variant) {
        return undefined;
    }
    if (variant === 'dark') {
        return backgroundClassNameDark;
    } else if (variant === 'darker') {
        return backgroundClassNameDarker;
    } else {
        throw new Error(`unknown background variant: ${variant}`);
    }
};

const addBodyClassNames = (variant?: UseBackgroundVariant) => {
    const classNameToAdd = getClassNameForVariant(variant);
    const body = document.getElementsByTagName('BODY')[0];
    if (classNameToAdd && !body.classList.contains(classNameToAdd)) {
        body.classList.add(classNameToAdd);
    }
};

export const useBackground = (variant?: UseBackgroundVariant) => {
    React.useEffect(() => {
        if (variant) {
            addBodyClassNames(variant);
        } else {
            removeBodyClassNames();
        }

        return () => {
            removeBodyClassNames();
        };
    }, [variant]);
};

import React from 'react';

import classNames from 'classnames';

// import { getFileDownloadUrl } from 'shared/utils/file';
import {defaultProfileImage} from 'shared/utils/assets';

import {ProfilePictureError} from './ProfilePictureError';

import './style.scss';

export interface IProfilePictureProps {
    url?: string | null;
    alt: string;
    round?: true;
    size?: 'xs' | 'sm' | 'md' | 'xmd' | 'lg' | 'xl' | 'xxl';
    border?: boolean;
    table?: boolean;
    className?: string,
    imgClassName?: string
}

export const ProfilePicture = ({
    alt,
    border,
    round,
    size,
    url,
    table,
    className,
    imgClassName,
}: IProfilePictureProps) => {
    const [hasError, setHasError] = React.useState<boolean>(false);

    const classNameComputed = classNames('ProfilePicture', className, {
        'ProfilePicture--round': round,
        'ProfilePicture--border': border,
        'ProfilePicture--table': table,
        'ProfilePicture--error': hasError,
        [`ProfilePicture--${size}`]: size,
    });

    const imageUrl = url ?? defaultProfileImage;

    return (
        <>
            {hasError && (
                <ProfilePictureError className={classNameComputed}/>
            )}
            {(!hasError && imageUrl) && (
                <img
                    src={imageUrl}
                    alt={alt}
                    className={`${classNameComputed} ${imgClassName || ''}`}
                    onError={() => setHasError(true)}
                />
            )}
            {(!hasError && !imageUrl) && (
                <div className={`${classNameComputed} ProfilePicture--placeholder ${imgClassName || ''}`}/>
            )}
        </>
    );
};

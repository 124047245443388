import {useEffect} from 'react';

import {useRecoilValue} from 'recoil';

import {tenantInfoAtom} from 'modules/tenant/state/tenant-info';

export const useTitle = (title?: string, overrideBaseTitle?: boolean) => {
    const tenantInfo = useRecoilValue(tenantInfoAtom);

    const appName = tenantInfo?.name ?? process.env.REACT_APP_NAME ?? 'Wyzed';

    useEffect(() => {
        if (!title) {
            document.title = appName;
        } else {
            document.title = overrideBaseTitle ? title : `${title} - ${appName}`;
        }
    }, [appName, overrideBaseTitle, title]);
};

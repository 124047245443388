export enum LegacyUserImageKind {
    User = 'USER',
    Tenant = 'TENANT',
}

export interface ILegacyUserImageKey {
    tenant_id: number;
    user_id: number;
    id: number;
    kind: LegacyUserImageKind;
}

export interface IFileKey {
    user_id: number;
    id: string;

    [key: string]: number | string;
}

export enum StorageLocation {
    Blobstore = 'blobstore',
    GoogleCloudStorage = 'gcs',
}

export enum StorageFolder {
    Default = 'default',
    Scorm = 'scorm',
    TenantLogo = 'tenant_logo',
    TenantCoverLogo = 'tenant_cover_logo',
    TenantCover = 'tenant_cover',
    UserPicture = 'user_picture',
    CourseCertificate = 'course_certificate',
    Temp = 'temp',
    Event = 'event',
}

export enum FileType {
    Default = 'default',
    Video = 'video',
    Audio = 'audio',
    Image = 'image',
    Pdf = 'pdf',
}

export enum FileFolder {
    Scorm = 'scorm',
    TenantLogo = 'tenant_logo',
    TenantCoverLogo = 'tenant_cover_logo',
    TenantCover = 'tenant_cover',
    UserPicture = 'user_picture',
    CourseCertificate = 'course_certificate',
    CourseCompletion = 'course_completion',
    Temp = 'temp',
    User = 'user',
    LMS = 'lms',
    Event = 'event',
}

export enum FileListOrder {
    FilenameAsc = 'FILENAME_ASC',
}

export interface IEntityKey {
    kind: string;
    id: number | string;
    parent?: IEntityKey;
}

export interface IFile {
    tenant_id: number;
    parent_key?: IEntityKey;
    legacy_key: string;
    id: number;
    user_id?: number;
    location: StorageLocation;
    folder: StorageFolder;
    bucket?: string;
    filename: string;
    content_type: string;
    size: number;
    serving_url?: string;
    video_key?: string;
    blob_key?: string;
    file_type: FileType;
    created_at: string;
    created_by?: number;
}

export type UserIDOrFolder = number | FileFolder;

export interface IFileQuery {
    folder?: string;
    user_id?: UserIDOrFolder;

    [key: string]: UserIDOrFolder | string | undefined;
}

export interface IFileListQuery extends IFileQuery {
    limit: number;
    cursor?: string;
    order?: FileListOrder;

    [key: string]: UserIDOrFolder | number | string | undefined | FileListOrder;
}

export interface IFileListResult {
    files: IFile[];
    next_cursor?: string;
    more: boolean;
}

export interface IFileCountResult {
    file_count?: number;
}

export interface IFileDownloadURL {
    url: string;
    expires_in_seconds: number;
}

export interface IFileToken {
    token: string;
    expires_in: number;
}

export interface IFileUpload {
    tenant_id: number;
    id: number;
    user_id?: number;
    upload_url: string;
    filename: string;
    folder: StorageFolder;
    content_type?: string;
    created_by: number | string | undefined;
    created_at: string;
}

export interface IFileUploadCreate {
    tenant_id: number;
    user_id?: number;
    filename: string;
    folder: string;
    content_type?: string;
}

export interface IUploadBlobToFileUploadURLProps {
    uploadURL: string;
    blob: Blob;
}

import React from 'react';

import ReactDOM from 'react-dom';
import {RecoilRoot} from 'recoil';
import {BrowserRouter} from 'react-router-dom';

import {Config} from 'config';
import reportWebVitals from './reportWebVitals';

import {AuthProvider} from 'shared/auth/components/AuthProvider/AuthProvider';
import {LoadingPage} from 'shared/components/loading/LoadingPage/LoadingPage';
import {TenantInfoProvider} from 'shared/auth/components/TenantInfoProvider/TenantInfoProvider';
import {ToastContainer} from 'modules/toast/components/ToastContainer';

import 'styles/_main.scss';

const App = React.lazy(() => import('App'));
const EventApp = React.lazy(() => import('LegacyApp/LegacyApp'));

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <TenantInfoProvider>
                    <AuthProvider apiUrl={Config.userServiceURL}>
                        {Config.baseURL ? (
                            <EventApp/>
                        ) : (
                            <App/>
                        )}
                    </AuthProvider>
                </TenantInfoProvider>
                <LoadingPage/>
            </BrowserRouter>
        </RecoilRoot>
        <ToastContainer/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
